<template>
  <PrimaryMenu />

  <Header title="Gyakran Ismételt Kérdések" />

  <SectionBlock>
    <SectionTitle title="Általános kérdések" />
    <SectionContent>
      <BAccordion free>
        <BAccordionItem :title="item.question" v-for="(item, i) in faqGeneral" :key="i">
          <div v-html="item.answer" />
        </BAccordionItem>
      </BAccordion>
    </SectionContent>
  </SectionBlock>

<!--  <SectionBlock class="pt-0">-->
<!--    <SectionTitle title="Bérléssel kapcsolatos kérdések" />-->
<!--    <SectionContent>-->
<!--      <BAccordion free>-->
<!--        <BAccordionItem :title="item.question" v-for="(item, i) in faqSingleRent" :key="i">-->
<!--          <div v-html="item.answer" />-->
<!--        </BAccordionItem>-->
<!--      </BAccordion>-->
<!--    </SectionContent>-->
<!--  </SectionBlock>-->

  <SectionBlock class="pt-0">
    <SectionTitle title="Rendezvényre történő bérléssel kapcsolatos kérdések" />
    <SectionContent>
      <BAccordion free>
        <BAccordionItem :title="item.question" v-for="(item, i) in faqEventRent" :key="i">
          <div v-html="item.answer" />
        </BAccordionItem>
      </BAccordion>
    </SectionContent>
  </SectionBlock>

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";

export default {
  name: "Faq",
  title: "Gyakran Ismételt Kérdések",
  components: {
    PrimaryMenu,
    Header,
    SectionBlock,
    SectionTitle,
    SectionContent,
    SectionContact,
    Footer,
  },
  data() {
    return {
      faqGeneral: [
        {
          question: "Mekkora gyerek használhatja?",
          answer:
            "Mivel autóink sebessége választható, de maximum 6 km/h, illetve biztonsági övvel és szülői távirányítóval felszereltek, így akár 1,5 éves kortól használhatóak egészen 7-8 éves korig. A súlyhatár a gyártó javaslata alapján 30 kg, de személyes tapasztalataink alapján nem mindegyik bírja, bérlés előtt javaslatot teszünk a z autó választásra. A kisautók kb. 120 cm magasságig kényelmesek, de egyes kisebb típusoknál a javasolt maximális magasság 110 cm.",
        },
        {
          question: "Hol található a kölcsönző?",
          answer:
            "Üzletünk címe: 1043 Budapest, Virág utca 17, bejárat a park felől.<br>Üzletünk körül kényelmes és ingyenes parkolás lehetséges.",
        },
      ],
      faqSingleRent: [
        {
          question: "Mikor vehetem át?",
          answer:
            "Otthoni bérlés esetén lehetőség van az üzletben történő átvételre vagy helyszíni kiszállításra. A kisautókat a tárgynapot megelőző este veheted át vagy szállítjuk ki 17.30 és 19.30 közötti időpontban. Kérjük, kiszállítás esetén ebben az időpontban tartózkodj a megadott címen! ",
        },
        {
          question: "Mikor kell visszavinnem?",
          answer:
            "A bérlés utolsó napján este 18.30-ig kell visszaszállítanod. Amennyiben kiszállítással kérted, 17.30 és 19.30 közötti időpontban érkezünk az autóért. Kiszállítás esetén az autó állapotának ellenőrzését követően kerül visszaadásra a kaució. Esetleges kár esetén a kár mértéke a kaucióból levonásra kerül. Ennek pontos menetét az Általános Szerződési Feltételekben megtalálod.",
        },
        {
          question: "Mennyibe kerül a kiszállítás?",
          answer: `
          <p><strong>Kiszállítás díja:</strong></p>
          <ul>
          <li>IV, XIII, XV. kerületek: 3 000 Ft</li>
          <li>I, II, III, V, VI, VII, VIII, X, XIV, XVI. kerületek, Dunakeszi, Fót: 4 000 Ft</li>
          <li>IX, XI, XII, XVII, XVIII, XIX, XX, XXI, XXII, XXIII. kerületek: 6 000 Ft</li>
          </ul>
          <p>16 000 Ft feletti megrendelés esetén a fenti településekre a kiszállítás <strong>INGYENES!</strong></p>
          <p>Fizetős parkolási övezetben minden esetben felszámítjuk a parkolás költségét.</p>
          <p><strong>Egyedi igények esetén kérje ajánlatunkat!</strong></p>
          <p><small>
            Az árak az oda-vissza szállítás költségét tartalmazzák!
            <br>A feltüntetett árak bruttó árak, 0% ÁFA-t tartalmaznak.
          </small></p>`,
        },
        {
          question: "Mit csináljak, ha használat közben lemerül az akkumulátor, vagy műszaki hiba lép fel?",
          answer: `<p>A kisautók akkumulátorai kb. 1-2 órás használatot bírnak ki egy feltöltéssel. Ez az idő nagyban függ az akkumulátor töltöttségi állapotától, a terepviszonyoktól, az autó használati módjától (sebesség meghatározás, hang effektek használatának mennyisége) és a gyermek méreteitől.
            <br>Próbáljuk úgy megtervezni utunkat, hogy az akkumulátor lemerülése előtt hazaérjünk. A feltöltött akkumulátor töltöttség jelzése maximális töltöttség esetén 12-13 között mozog. Amikor ez a szám 10 egységet mutat, érdemes az otthonunk vagy a szállító személygépjármű felé venni az utat. Az autó teljes lemerülése esetén leáll, így a célig kézben lesz szükséges vinni az autót. Ebben az esetben egy személynél a szabályos emelési mód a következő: átlósan a két kerékdob felett lehet emelni az autót. KORMÁNYNÁL, EGYÉB MŰANYAG ALKATRÉSZEKNÉL AZ AUTÓT EMELNI TILOS A SÉRÜLÉS VESZÉLYE MIATT! Ilyen sérülés esetén a javítási költség a kaucióból levonásra kerül.
            <br>Amennyiben már hálózati töltés közelében vagyunk, a kisautó töltőjét az autóba és a konnektorba csatlakoztatva megkezdhetjük a töltést. Várjunk néhány órát, akár egy éjszakát is a következő használat előtt.
            <br>Bármilyen műszaki hiba esetén (nem indul az autó, nem tölt a töltő) azonnal hívjon minket a 0630/7488928-as telefonszámon, és segítünk orvosolni a problémát, ha szükséges, akár személyesen is!</p>`,
        },
      ],
      faqEventRent: [
        {
          question: "Mekkora terület szükséges az autózáshoz?",
          answer:
            "Ha több autóval szeretnétek egyszerre közlekedni, az általunk javasolt terület minimum 100 nm legyen.  Amennyiben ez nem megoldható, a bérlés akkor is megvalósítható, viszont ebben az esetben az autózás kényelmetlenebb, kevésbé élvezhető lehet.\n" +
            "Az ideális terep sík, aszfaltozott.",
        },
        {
          question: "Mennyibe kerül a kiszállítás?",
          answer:
            "A kiszállítás díja minden esetben egyéni megállapodás alapján kerül meghatározásra, így rendeléskor erről érdeklődj szóban vagy írásban.",
        },
        {
          question: "Mit csináljak, ha használat közben lemerül az akkumulátor, vagy műszaki hiba lép fel?",
          answer:
            "Mindegyik csomag ajánlatunk tartalmazza a személyes felügyeletet a rendezvény ideje alatt, így a felmerült problémát azonnal meg tudjuk oldani. \n" +
            "A rendezvényekre plusz akkumulátorokkal érkezünk, de ajánlott a 220V áramforrás biztosítása részünkre.",
        },
        {
          question: "Meg szeretném rendelni a szolgáltatást, kell előre fizetnem?",
          answer:
            "A szolgáltatás megrendelését követően 20% összegű foglaló szükséges. Amennyiben a Megrendelő a szolgáltatás időpontja előtt 5 napon belül lemondja a szolgáltatást, a foglalót elveszti. Amennyiben a Szolgáltató hibájából nem valósul meg a szolgáltatás, a foglalót kétszeresen köteles visszafizetni a Megrendelő számára. Ha a szolgáltatás teljesítése olyan okból nem valósul meg, amelyért egyikük sem felelős, vagy mindkét fél felelős, a foglaló visszajár. A szolgáltatás teljesítését követően a foglaló a vételárba beleszámít.",
        },
        {
          question:
            "2 órás időtartamra rendeltem meg a szolgáltatást, de időközben meggondoltam magam. Lehetséges a hosszabbítás?",
          answer:
            "Amennyiben van a plusz időre szabad kapacitásunk, lehetséges a hosszabbítás akár a rendezvény közben is, ennek díja 12 000 Ft / óra.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.accordion {
  &-button {
    font-weight: $font-weight-bolder;
  }
}
</style>
